.wrapper {
  padding: 8px;
  margin-bottom: 16px;
}

.navButton {
  fill: #aaa;
}

.navButton:disabled {
  fill: #ddd;
}

.filterButton {
  fill: #ddd;
}

.filterButton[aria-expanded="true"] {
  fill: #aaa;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.filterList > li {
  display: flex;
  align-items: center;
}

.filterList > li:nth-child(even) {
  background-color: #f3f3f3;
}

.deleteClauseButton {
  fill: #aaa;
}

.addClauseButton {
  fill: #aaa;
}

.addClauseCell {
  text-align: center;
  width: 50px;
}
