.amountCell {
  width: 60px;
}

.amountCell > input {
  width: 60px;
}

.deleteButton {
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
}

.deleteButton:disabled > svg {
  fill: #eee;
}
.deleteButton > svg {
  fill: #aaa;
}

.input {
  border: 1px solid #eee;
}

.increaseInput {
  border: 1px solid #eee;
  box-shadow: 0 0 2px rgba(0, 255, 0, 0.2);
}

.decreaseInput {
  border: 1px solid #eee;
  box-shadow: 0 0 2px rgba(255, 0, 0, 0.2);
}
