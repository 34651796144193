.accountList {
  list-style: none;
  padding: 0;
}

.caret {
  padding: 0;
}

.rootAccountList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.accountLine {
  font-size: 14px;
  border-left: 2px solid rgba(255, 255, 255, 0);
  padding: 4px 0;
}

.accountLine:hover {
  background-color: #fafafa;
}

.selectedAccountLine {
  border-left: 2px solid var(--color-blue-lighter);
}

button.onlyButton {
  display: none;
  float: right;
  color: #666;
  font-size: 0.8em;
  line-height: 1em;
}

.onlyButton:hover {
  text-decoration: underline;
}

.accountLine:hover .onlyButton {
  display: inline-block;
}
