.leftNavWrapper {
  flex: 0 0 42px;
  transition: flex-basis 200ms ease-in-out;
}

.leftNavWrapper:hover {
  flex-basis: 200px;
}

.leftNav {
  position: fixed;
  z-index: 10;
  width: 42px;
  top: 0;
  bottom: 0;
  left: 0;

  background-color: var(--color-blue-dark);
  transition: width 200ms ease-in-out;

  overflow: hidden;
}

.logoWrapper {
  box-sizing: border-box;
  height: 64px;
  padding: 12px;
}

.logo {
  margin: 12px 0;
  width: 18px;
  height: 18px;
  background-image: url("assets/ducount.svg");
  background-repeat: no-repeat;

  transition: margin 200ms ease-in-out;
}

.leftNav:hover .logo {
  margin-left: 79px;
}

.leftNav:hover {
  width: 200px;
}

.leftNav > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.leftNav li {
}

.leftNav li:hover {
}

.leftNav button {
  background: none;
  border: none;
  outline: none;
  text-align: start;

  display: flex;
  width: 100%;

  padding: 8px 12px;

  font-size: 16px;

  white-space: nowrap;

  color: var(--color-blue-lighter);
}

.navIcon {
  display: block;
  flex: 0 0;
}

.navText {
  display: block;
  padding: 0 8px;
  flex: 0 0;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.leftNav:hover .navText {
  opacity: 1;
}

.leftNav button:hover {
  background-color: var(--color-blue-medium);
}

.mainContent {
  flex: 0 0 calc(100% - 42px);
}

.footer {
  padding: 8px;
  color: #ccc;
  text-align: right;
}
