.account {
  position: relative;

  margin: 16px;
  padding: 8px;
}

.actions {
  position: sticky;
  top: 0;
  z-index: 1;
  margin: 0 16px;
  padding: 8px;
}

.actionList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.actionList li {
  display: inline-block;
  margin-right: 8px;
}

.accountUnselected {
  filter: blur(2px);
  pointer-events: none;

  min-height: 180px;
}

.accountSelector {
  background-color: white;
  border: 1px solid #eee;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  margin-top: 16px;
}

.accountSelectorOverlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.accountSelectorInput {
  display: inline-block;
  text-align: start;
}

.entryTable {
  width: 100%;
  border-collapse: collapse;
}

.entryTable tr {
  border: 1px solid #ddd;
}

.entryTable td {
  padding: 4px;
}

.checkboxCell {
  width: 16px;
}

.amountCell {
  width: 60px;
  text-align: right;
}

.otherAccountSelectorCell {
  width: 120px;
}

.dateCell {
  width: 80px;
}

.otherAccountSelectorInner {
  display: flex;
  align-items: center;
}

.clearTransactionButton {
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: none;
  border: none;
  padding: 0;
}

.clearTransactionButton svg {
  fill: #ddd;
}
