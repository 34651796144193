.accounts {
  flex: 1 1;
  padding: 16px;
}

.searchBar {
  background-color: white;

  position: sticky;
  top: 0;
  padding: 8px 0;
}
