.styleless {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1em;
  padding: 0;
  text-align: start;
}

.styleless:disabled {
  cursor: auto;
}

.button {
  background-color: white;
  border: 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  padding: 4px 12px;
  outline: none;
  cursor: pointer;
}

.button:active {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  transform: translateX(1px) translateY(1px);
}

.segmentWrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.segmentButtonWrapper {
  display: inline-block;
}

.segmentButton {
  border: none;
  margin: 0;
  padding: 4px 12px;
  outline: none;
  cursor: pointer;
  background-color: white;
}

.buttonPurple {
  background-color: var(--color-purple-medium);
  color: white;
}

.buttonBlue {
  background-color: var(--color-blue-medium);
  color: white;
}

.buttonPurpleOutline {
  border: 1px solid var(--color-purple-medium);
  padding: 3px 11px;
  color: var(--color-purple-medium);
}

.buttonBlueOutline {
  border: 1px solid var(--color-blue-lighter);
  padding: 3px 11px;
  color: var(--color-blue-medium);
}
