.emptyDateBox {
  width: 160px;
}

.dateBox {
  box-sizing: border-box;
  text-align: end;
  font-weight: bold;
  font-size: 16px;
  color: var(--color-blue-lighter);
  padding: 8px;

  width: 160px;
  border-top: 1px solid var(--color-blue-light);
}

.checkboxBox {
  border-top: 1px solid var(--color-blue-light);
  padding: 8px;
}

.transactionLine {
  display: flex;
  max-width: 680px;
  margin: 24px 0;
}

.splitsBox {
  flex: 1 0;
  border-top: 1px solid var(--color-blue-light);

  font-size: 14px;
}

.splitsBox p {
  margin: 8px 0;
  font-weight: 500;
}

.splitsBox table {
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
}

.splitsBox table td {
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid var(--color-blue-lightest);
}

.amountCell {
  width: 100px;
  text-align: end;
  padding-right: 4px;
}
