.multiAccountInput {
  border: 1px solid #eee;
  display: inline-flex;
}

.accountSelectContainer {
  flex-grow: 1;
}

.accountSelectInput {
  border: none;
  outline: none;
}

.token {
  font-size: 0.8em;
  border: 1px solid #aaa;
}
