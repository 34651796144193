.wrapper {
  padding: 8px;
}

.ledgerTable {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border-collapse: collapse;
}

.evenRow {
  background-color: #e9ecf0;
}

.expandedRow {
  background-color: var(--color-blue-lightest);
}

.expandRow {
  background-color: var(--color-yellow-lighter);
}

.ledgerTable > tbody > tr {
  transition: background-color 0.16s ease;
}

.ledgerTable > thead > tr > th,
.ledgerTable > tbody > tr > td,
.ledgerTable > tfoot > tr > td {
  padding: 4px;
  border: 1px solid var(--color-blue-lighter);
}

.ledgerTable > tfoot {
  background-color: var(--color-blue-lightest);
  font-weight: 500;
}

.checkboxCell {
  width: 20px;
}

.dateCell {
  width: 60px;
}

.accountCell {
  width: 120px;
}

.amountCell {
  width: 80px;
}

td.amountCell {
  text-align: right;
}

.expandCell {
  width: 16px;
}
