.pageWrapper {
  margin: 0 16px;

  --page-title-height: 70px;
}

.block {
  background-color: white;
  box-shadow: 0 0 16px 0 rgba(166, 172, 180, 0.15);
}

.pageTitle {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  padding: 24px 0;
  color: var(--color-blue-darker);
  box-sizing: border-box;
  height: var(--page-title-height);
}

.pageTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pageTitleNav {
}
