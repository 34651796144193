.accountDisplay {
  display: inline-block;
}

.accountDisplay p {
  font-size: 0.6em;
  margin: 0;
  padding: 0;
}

button.accountSelector {
  background: none;
  appearance: none;
  cursor: text;
  text-align: start;
  padding: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
